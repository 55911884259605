@import-normalize;

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 1em;
  min-width: 320px;
}

button {
  font-size: inherit;
  font-weight: inherit;
}

body {
  margin: 0;
  color: #000;
  /* font-family: "Muli", sans-serif; */
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  color: #2f2f2f;
}

body.modalShowing {
  position: fixed;
}

input {
  /* font-family: "Muli", sans-serif; */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /* background-color: transparent !important; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none !important;
  /* all: unset; */
  background: none;
}

button {
  border: 0;
  padding: 0;
}

input[type="search"],
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

h1,
h2,
h3,
h4,
h5 {
  /* font-family: "Muli", sans-serif; */
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: 46px;
  line-height: 54px;
}

h2 {
  font-size: 28px;
  line-height: 36px;
}

h3 {
  font-size: 26px;
  line-height: 34px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 22px;
  line-height: 30px;
}

h6 {
  font-size: 20px;
  line-height: 26px;
}

h5,
h6 {
  font-weight: 800;
}

/* TABLET */
@media (max-width: 992px) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 35px;
    line-height: 40px;
  }

  h2 {
    font-size: 26px;
    line-height: 34px;
  }

  h3,
  h4 {
    font-size: 22px;
    line-height: 30px;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
  }

  h6 {
    font-size: 18px;
    line-height: 24px;
  }
}

/* MOBILE */
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 32px;
  }

  h2 {
    font-size: 24px;
    line-height: 26px;
  }

  h3,
  h4 {
    line-height: 26px;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 18px;
  }
  h5,
  h6 {
    line-height: 22px;
  }
}

/* Hide recaptcha badge  */
.grecaptcha-badge {
  visibility: hidden;
}

a.back::before {
  content: url("assets/images/backArrow.png");
  margin-right: 10px;
}

.react-tabs ul {
  padding-left: 0px;
  border-bottom: 1px solid #d4d4d4;
}

.react-tabs ul li {
  padding-left: 0px;
  padding-right: 25px;
  padding-bottom: 5px;
}

.react-tabs ul li span {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 4px;
}

.react-tabs ul li span.selected {
  color: #228192;
  border-bottom: 3px solid #228192;
}

.align-ul ul {
  padding-left: 0;
  margin-left: 1.5em;
  border-bottom: none;
}

.site-footer-section a {
  align-items: flex-start;
}

.site-footer-section div {
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .site-footer-section {
    display: grid !important;
    grid-template-columns: 1fr !important;
    justify-content: center;
    align-items: center;
  }

  .site-footer-section a {
    align-items: center;
  }

  .site-footer-section div {
    justify-content: center;
  }

  .site-footer-section p {
    text-align: center;
  }
}
